<template>
    <div>
        <div class="content-nav">
            <div class="content-nav-item"
                 v-for="(item,index) in navList.children"
                 :class="{active:$route.path.indexOf(item.path)>=0}"
                 @click="tpPage(item)"
                 :key="index">
                <i class="iconfont" :class="item.icon"></i>
                <div>{{item.title}}</div>
                <span v-if="item.title=='兑换记录' && todoNumber" class="badge">{{todoNumber}}</span>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: "index",
        data(){
            return{
                parkId: null,
                todoNumber: 0,
            }
        },
        computed:{
            ...mapState('layout', {
                menuList: state => state.menuList
            }),
            ...mapState('user', {
                userInfo: state => state.userInfo
            }),
            navList(){
                return this.menuList.find(item=>item.path === '/price')
            }
        },
        created(){
            this.getHandleExchangeNum();
        },
        methods:{
            tpPage({path}){
                this.$router.push({path})
            },
            //获取未处理的兑换信息
			async getHandleExchangeNum() {
				let [e, data] = await this.$api.getHandleExchangeNum(this.searchForm);
				if (e) return;
				if (data.code === 200) {
					console.log(data);
                    this.todoNumber = data.data;
				}
			},
        }
    }
</script>

<style scoped lang="less">
.content-nav-item {
    position: relative;
}
.badge {
    position: absolute;
    top: -15px;
    left: 90%;
    color: #fff;
    min-width: 15px;
    height: 15px;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    background-color: red;
    padding: 3px;
    border-radius: 15px;
}
</style>
